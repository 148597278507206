import { FC, useContext } from "react";
import { Styled } from "./_styles";
import { useNavigate } from "react-router-dom";
import { IWidgetContext, WidgetContext } from "../../context";
import { LG } from "../../constants";
import { addTracking, toNextPage } from "../../store/tracking/tracking";
import { useDispatch } from "../../store/store";

export const Header: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    config: { lg },
    searchParams,
    setSearchParams,
  } = useContext<IWidgetContext>(WidgetContext);
  const title =
    searchParams?.get("page") === "order"
      ? LG[lg].orderCard
      : LG[lg].trackDelivery;

  const clearAndBack = () => {
    setSearchParams({});
    dispatch(toNextPage(false));
    dispatch(addTracking(""));
    navigate("/");
  };

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderLogo onClick={clearAndBack} />
      <Styled.HeaderTitle>{title}</Styled.HeaderTitle>
    </Styled.HeaderContainer>
  );
};
